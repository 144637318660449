((FUNCTIONS) => {
	const a = 'is-active';
	const v = 'is-visible';

	// ＜ルート設定対応＞要素が空だったら削除
	const removeElmIfEmpty = () => {
		const $eleList = document.querySelectorAll('.js-checkEmptyElm');
		$eleList.forEach(ele => {
			if (!ele.textContent.length) {
				ele.remove();
			}
		});
	}
	// ＜ルート設定対応＞該当のリストにルート設定出力が0件の場合、指定する親ブロックごと削除する
	const removeBlockIfListEmpty = () => {
		const $eleList = document.querySelectorAll('.js-checkEmptyList');
		if ($eleList) {
			$eleList.forEach(ele => {
				if (!ele.getElementsByTagName('li')) {
					ele.closest('.js-checkEmptyList-wrap').remove();
				}
			});
		}
	};
	const slider = () => {
		// newway_newvaluw記事スライダー3カラム
		if(document.querySelectorAll('.mod-slider01').length) {
			new Swiper('.mod-slider01', {
				slidesPerView: 1,
				spaceBetween: 0,
				// loop: true,
				// loopAdditionalSlides: 1,
				speed: 1000,
				autoplay: false,
				watchSlidesProgress: true,
				navigation: {
					nextEl: '.mod-slider01-control .swiper-button-next',
					prevEl: '.mod-slider01-control .swiper-button-prev',
				},
				breakpoints: {
				  1024: {
					slidesPerView: 3,
				  },
				},
			});
		}
		if(document.querySelectorAll('.mod-slider02').length) {
			document.querySelectorAll('.mod-slider02').forEach((ele,idx) => {
				let sliderid = 'mod-slider02_'+idx;
				// 同ページに複数スライダー設置の際にユニークIDを付与
				ele.setAttribute('id',sliderid);
				sliderid = '#'+sliderid;
				new Swiper(sliderid, {
					slidesPerView: 1,
					spaceBetween: 0,
					// loop: true,
					// loopAdditionalSlides: 1,
					speed: 1000,
					autoplay: false,
					watchSlidesProgress: true,
					navigation: {
						nextEl: sliderid + ' + .mod-slider02-control .swiper-button-next',
						prevEl: sliderid + ' + .mod-slider02-control .swiper-button-prev',
					},
					breakpoints: {
					  1024: {
						slidesPerView: 2,
					  },
					},
				});
			});
		}
	};
	removeElmIfEmpty();
	removeBlockIfListEmpty();
	slider();
})(window.FUNCTIONS);
